import { autoinject, computedFrom, PLATFORM } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router, RouterConfiguration } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { AccountRegisterRequest, MyHttpApi, Session } from 'utils/api';
import { BrandUtil } from 'utils/brand';
import { Notify } from 'utils/notify';
import { TranslationUtil } from 'utils/translation-util';
import config from '../config/environment.json';

@autoinject
export class App {
  private sessionNotifyTimer?: NodeJS.Timeout;
  private menu = false;
  private language = false;
  private googleAnalytics = false;
  private defaultLoginCallback = () => this.router.navigateToRoute("/");
  
  constructor(private notify: Notify, private client: MyHttpApi, private router: Router, private i18n: I18N, private bindingSignaler: BindingSignaler, private translationUtil: TranslationUtil) {
  }

  configureRouter(routerConfiguration: RouterConfiguration, router: Router) {
    this.router = router;
    routerConfiguration.options.pushState = true;
    routerConfiguration.options.root = "/";
    routerConfiguration.map([
      { title: "menu.storeList", route: "", moduleId: PLATFORM.moduleName("index/index"), name: "/", nav: false },
      { route: "shop/:id?", moduleId: PLATFORM.moduleName("shop/index"), name: "shop", },
      { route: "gift-shop/:id?", moduleId: PLATFORM.moduleName("gift-shop/index"), name: "gift-shop", },
      {
        title: "menu.orders", route: "confirmations", moduleId: PLATFORM.moduleName("confirmation/list"), name: "confirmations", nav: true,
        settings: { permission: (session?: Session) => !!session }
      },
      {
        route: "confirmation/:id", moduleId: PLATFORM.moduleName("confirmation/show"), name: "confirmation/show",
        settings: { permission: (session?: Session) => !!session }
      },
      {
        title: "menu.accountEdit", route: "account/edit", moduleId: PLATFORM.moduleName("account/edit"), name: "account/edit", nav: true,
        settings: { permission: (session?: Session) => !!session }
      },
      {
        route: "session/set-password", moduleId: PLATFORM.moduleName("session/set-password"), name: "session/set-password", nav: false,
        settings: { permission: () => true },
      },
      {
        title: "menu.gdpr", route: "gdpr/request", moduleId: PLATFORM.moduleName("gdpr/request"), name: "gdpr/request", nav: true,
        settings: { permission: (session?: Session) => !session }
      },
      {
        title: "menu.gdpr", route: "gdpr/", moduleId: PLATFORM.moduleName("gdpr/data"), name: "gdpr/data", nav: true,
        settings: { permission: (session?: Session) => !!session }
      },
      {
        title: "menu.gdpr", route: "gdpr/login", moduleId: PLATFORM.moduleName("gdpr/login"), name: "gdpr/login", 
        settings: { permission: (session?: Session) => true }
      },
    ]);
    routerConfiguration.fallbackRoute("/");
  }

  async activate() {
    await this.sessionNotify();
    this.sessionNotifyTimer = setInterval(() => this.sessionNotify(), 60000);

    {
      /* look for store id based on valid entry points URLs. Hostname lookup overrules any given ID. */
      let id = location.href.match(/(?:shop|gift-shop)\/(\d+)/);
      this.notify.store = await this.client.publicStoreById({ id: id && id[1] ? parseInt(id[1]) : undefined });
    }

    if (this.notify.store) {
      for (let tr of this.notify.store.translations) {
        this.i18n.i18next.addResource(tr.language.toLowerCase(), "store", tr.key, tr.value);
      }
      if (!localStorage.getItem("nettikauppa-language")) {
        await this.setLang(this.notify.store.store.defaultLanguage.toLowerCase());
      }

      /* setup router.title custom translation */
      this.i18n.i18next.addResource("fi", "store", "notify.store.name", this.notify.store.store.name);
      this.i18n.i18next.addResource("sv", "store", "notify.store.name", this.notify.store.store.nameSv || this.notify.store.store.name);
      this.i18n.i18next.addResource("en", "store", "notify.store.name", this.notify.store.store.nameEn || this.notify.store.store.name);
      this.router.title = "notify.store.name";
      this.bindingSignaler.signal("aurelia-translation-signal");

      if (this.notify.store.store.gaId) {
        /* Auto-click whatever user clicked last time */
        let consent = localStorage.getItem(config.sessionKey + "-ga");
        if (consent) {
          this.createTagManager(this.notify.store.store.gaId);
        } else {
          this.googleAnalytics = true;
        }
      }
    }
  }

  deactivate() {
    clearInterval(this.sessionNotifyTimer);
  }

  googleAnalyticsConsent(ok: boolean) {
    this.googleAnalytics = false;
    localStorage.setItem(config.sessionKey + "-ga", "" + ok);
    if (!ok) {
      return;
    }

    /* Should always be OK, we are conditioned on store & gaId existing; here we just prove it to TS */
    if (!this.notify.store?.store.gaId) {
      return;
    }

    // @ts-ignore
    this.createTagManager(this.notify.store.store.gaId);
  }

  private createTagManager(gaId: string) {
    let dataLayer: any[] = []
    let gtag = function () { dataLayer.push(arguments); };
    // @ts-ignore
    gtag("js", new Date());
    // @ts-ignore
    gtag("config", gaId);
    // @ts-ignore
    window.dataLayer = dataLayer;

    let script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=" + gaId;
    document.head.appendChild(script);
  }

  async sessionNotify() {
    try {
      let result = await this.client.sessionStatus();
      if (result.abbreviatedSha && this.abbreviatedSha && !result.abbreviatedSha.startsWith("$") && result.abbreviatedSha !== this.abbreviatedSha) {
        /* Attempt to trigger a no-caches reload of app */
        console.log("Application expired", result.abbreviatedSha, "vs", this.abbreviatedSha);
        window.location.reload();
      }
      if (!this.client.session && result.session) {
        this.client.session = result.session;
      }
      if (this.client.session && !result.session) {
        this.client.session = undefined;
        this.router.navigateToRoute("session/login");
      }
    } catch (e) {
      // Empty on purpose
    }
  }

  @computedFrom()
  get abbreviatedSha(): string {
    // @ts-ignore this constant comes from DefinePlugin, typescript won't know about it.
    return ABBREVIATED_SHA;
  }

  @computedFrom("i18n.i18next.language")
  get lang() {
    return this.i18n.i18next.language;
  }

  async setLang(locale: string) {
    await this.i18n.setLocale(locale);
    localStorage.setItem("nettikauppa-language", locale);
  }

  @computedFrom("notify.store")
  get brandStyle() {
    if (!this.notify.store) {
      return;
    } else if (this.notify.store.storeBrand?.style) {
      return `*/ ${this.notify.store.storeBrand.style} /*`;
    } else {
      return BrandUtil.getCommentedCss(this.notify.store.businessUnitBrand, this.client);
    }
  }
  
  toggleMenu() {
    this.menu = !this.menu;
    return true;
  }

  toggleLanguage() {
    this.language = !this.language;
    return true;
  }

  /* Welcome to callback hell.
   * If this is your first time around, allow me to show you the ropes.
   *
   * login = Kirjaudu. Again, hamburger menu link. Login can result in both register or forgot password flows.
   * After registering, it invokes the login callback, which may do nothing. Or, it may send you to some specific action or do whatever.
   * 
   * Forgot password asks for registration code. After getting it, it reuses the registration code entry, and if successful,
   * send you forwards.
   * 
   */
  showLogin() {
    this.notify.loginModal = true;
  }
  loginComplete() {
    this.notify.loginModal = false;
    (this.notify.loginCallback || this.defaultLoginCallback)();
  }

  async logout() {
    await this.client.sessionLogout();
    this.client.session = undefined;
    this.router.navigateToRoute("/");
  }

  showForgotPassword() {
    /* If session is created as result of this flow, we must change password afterwards. */
    this.notify.loginModal = false;
    this.notify.forgotPasswordModal = true;
  }
  forgotPasswordComplete() {
    this.notify.forgotPasswordModal = false;
  }
}
